import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Alert from 'react-bootstrap/Alert';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';

import BookingSelect from './BookingSelect'
import DateSlots from './DateSlot';
//import timeSlotsJson from '../data/slots.json';
import TimeSlots from './TimeSlots';
import { BASE_URL, AVAILABLE_SLOTS } from '../config/constants';

import { useSearchParams } from 'react-router-dom';

//import { BookingdetailsContext } from '../providers/bookingDetails';

//import ConfirmationModal from './AppBookConfirmation';
//import Row from 'react-bootstrap/Row';
//import Col from 'react-bootstrap/Col';
function Home() {
  const navigate = useNavigate();
  const [morningSlots, setMorningSlots] = useState([]);
  const [afternoonSlots, setAfternoonSlots] = useState([]);
  const [eveningSlots, setEveningSlots] = useState([]);
  const [showSlots, setShowSlots] = useState(false);
  const [showNoSlotsAlert, setShowNoSlotsAlert] = useState(false); 
  //const [responseError,setResponseError]=useState(false);
  //const { docID, setDocID, specID, setSpecID, doctnumber, setDoctnumber } = useContext(BookingdetailsContext);
  //const [showModal,setShowModal] =useState(false);
  //const [modalData, setModalData] = useState({});
  //console.debug(timeSlotsJson.hospitaldoctorAvailableSlots.morningSlots)
  const handleCallBackFromDateSlots = () => {
    populateShiftSlots();
  }
  const handleCallBackFromBooking = () => {
    populateShiftSlots()
  }
  // const test = () => {
  //   console.debug(`context DocID -- ${docID}`);
  //   console.debug(`context doctnumber -- ${doctnumber}`);
  // }
  const [searchParams] = useSearchParams();
  const requestFrom = searchParams.get('requestFrom') || '555';

    
    
  const populateShiftSlots = () => {
    document.getElementById("submitAppointment")?.setAttribute("disabled","true");
    let doctorElement = document.getElementById("Doctor");
    let doctorElementId = doctorElement.value;
    let doctnumberTemp = doctorElement.options[doctorElement.selectedIndex].getAttribute('doctnumber');
    let categoryElementId = document.getElementById("Category").value;

 
    // console.debug(`Populate shifts slots requested with doctnumber -- ${doctnumber}`);
    //console.debug(`PopulatePopulate shifts slots requested with doctorElementId -- ${doctorElementId}`);
    // let doctorElementId = docID;
    // let categoryElementId = specID;
    let date = Array.from(document.getElementsByClassName("slot btn-primary"))[0]?.textContent?.trim();
    if (doctorElementId !== '-1' && categoryElementId !== '-1') {
      // console.debug(`Populate shifts slots requested with specialization ID -- ${categoryElementId} doc ID ${doctorElementId} request for ${requestFrom}`);
      fetch(`${BASE_URL}${AVAILABLE_SLOTS}/${doctorElementId}?date=${date}&doctnumber=${encodeURIComponent(doctnumberTemp)}&requestfrom=${encodeURIComponent(requestFrom)}`)
        .then((res) => res.json())
        .then((data) => {
          let hospitalID = data.hospitals?.[0].id;
          //console.debug("Response for available dates query" + date, data);
          //console.debug('data.hospitalAvailableSlots[8].length',data.hospitalAvailableSlots[8].length);
          if (data && data.hospitalAvailableSlots?.[hospitalID]) {
            //console.debug('available slots', data.hospitalAvailableSlots)
            let availableSlotsinDay = { ...data.hospitalAvailableSlots[hospitalID] }
            setMorningSlots(availableSlotsinDay.morningSlots);
            setAfternoonSlots(availableSlotsinDay.afternoonSlots);
            setEveningSlots(availableSlotsinDay.eveningSlots);
            setShowSlots(true);
          } else {
            console.debug('slots not available')
            setShowNoSlotsAlert(true);
            setShowSlots(false);
          }
        });
    } else {
      setMorningSlots([]);
      setAfternoonSlots([]);
      setEveningSlots([]);
      setShowSlots(false);
      setShowNoSlotsAlert(false);
    }

  }
  const AlertBox = () => {
    if (showNoSlotsAlert) {
      return (<Alert variant="danger" className='no-margin' onClose={() => setShowNoSlotsAlert(false)} dismissible>
        <Alert.Heading>Oh Sorry!</Alert.Heading>
        <p>
          No Schedule available for this date. Try changing the dates.
        </p>
      </Alert>)
    } else {
      return null;
    }

  }

  function openConfirmation() {
    var categorySelect = document.getElementById("Category");
    var doctorSelect = document.getElementById("Doctor");
    var doctnumber = doctorSelect.options[doctorSelect.selectedIndex].getAttribute('doctnumber');
    var hosDoctorUniqId = doctorSelect.options[doctorSelect.selectedIndex].getAttribute('hosdoctoruniqid');
    var categoryText = categorySelect.options[categorySelect.selectedIndex].text;
    var categoryId = categorySelect.options[categorySelect.selectedIndex].value;
    var doctorText = doctorSelect.options[doctorSelect.selectedIndex].text;
    var doctorId = doctorSelect.options[doctorSelect.selectedIndex].value;
    var shiftSlots = Array.from(document.getElementsByClassName("shiftslots bg-primary"))[0]?.textContent;
    const requestFrom = searchParams.get('requestFrom') || '555';

    // console.debug(' hosDoctorUniqId wwww ',doctorSelect.options[doctorSelect.selectedIndex]);

    var formInvalid = false;
    if (categoryId === -1) {
      categorySelect.classList.add("is-invalid")
      formInvalid = true;
      categorySelect.focus();
    }
    if (doctorId === -1) {
      doctorSelect.classList.add("is-invalid")
      doctorSelect.focus();
      formInvalid = true;
    }
    if (doctorId === -1) {
      doctorSelect.classList.add("is-invalid")
      doctorSelect.focus();
      formInvalid = true;
    }
    if (!shiftSlots) {
      console.log('No Slot selected');
      formInvalid = true;
    }
    if (!formInvalid) {
      
      navigate('/confirmation', {
        state: {
          "Category": categoryText,
          "CategoryId": categoryId,
          "Doctor": doctorText,
          "DoctorId": doctorId,
          "Date": Array.from(document.getElementsByClassName("slot btn-primary"))[0]?.textContent?.trim(),
          "ShiftSlots": shiftSlots,
          "tphonenumber": doctnumber,
          "doctorUniqId":hosDoctorUniqId,
          "lastURL":window.location.href,
          "requestFrom":requestFrom,
        },
      });
    }
  }
  // function closeModel(show) {
  //   //setShowModal(show);
  // }
  return (
    <Container>
      <BookingSelect callbackHandler={handleCallBackFromBooking} />
      <DateSlots days={7} callbackHandler={handleCallBackFromDateSlots} />
      {showSlots && morningSlots.length !== 0 ? <TimeSlots shift="Morning" show={showSlots} timeSlotsJson={morningSlots} /> : null}
      {showSlots && afternoonSlots.length !== 0 ? <TimeSlots shift="Afternoon" show={showSlots} timeSlotsJson={afternoonSlots} /> : null}
      {showSlots && eveningSlots.length !== 0 ? <TimeSlots shift="Evening" show={showSlots} timeSlotsJson={eveningSlots} /> : null}
      {showSlots ? <Button id="submitAppointment" className="float-end danger small" onClick={openConfirmation}>
        Submit
      </Button> : <div className="card"> <AlertBox /></div>}
      {/* <div>Debug : specialisation ID --- {specID} doctor ID -- {docID}</div> */}
    </Container>
  );
}
// onClose={() => setShow(false)} dismissible
export default Home;
