import Form from 'react-bootstrap/Form';
//import props from 'prop-types';
import { useState, useEffect } from 'react';
function DropDownRx(props) {
  const [options, setOptions] = useState([])
  //const [data, setData] = useState(props.data);
  const [optionText, setOptionText] = useState('None');
  const [optionID, setOptionID] = useState(-1);
  useEffect(() => {
  setOptionID(props.selected);
    let rows = [];
    let dataObject = props.data.forEach((currentObj) => {
      rows.push(<option doctnumber={currentObj.tnumber} hosDoctorUniqId={currentObj.doctoruniqid} value={currentObj.key} key={currentObj.key}>{currentObj.value}</option>);
    })
    setOptions(rows);
  }, [props.data])
  // function handleChange(event) {
  //   console.debug(event.target.value)
  //   if (event.target.value != -1) {
  //     event.target.classList.remove("is-invalid");
  //   }
  //   props.callbackHandler(event.target.value);
  // }
  const handleChange = (event) => {
    let index = event.nativeEvent.target.selectedIndex;
    let text = event.nativeEvent.target[index].text;
    let optionValue = event.target.value;
    //console.debug('dropdown selected', event.target.value,text);
    if (optionValue !== -1) {
      event.target.classList.remove("is-invalid");
    }
    setOptionText(text);
    setOptionID(optionValue);
    props.callbackHandler(optionValue, text);
  }
  return (
    <Form.Group>
      <Form.Label>{props.label}</Form.Label>
      <Form.Select style={{ whiteSpace: 'normal' }} aria-label="Default select example" id={props.label} onChange={handleChange} value={optionID}>
        <option value='-1' key='-1'>None</option>
        {options}
      </Form.Select>
      <div className="invalid-feedback">
        Please select a {props.label}.
      </div>
    </Form.Group>
  );
}

export default DropDownRx;