import { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import InputGroup from 'react-bootstrap/InputGroup';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Card from 'react-bootstrap/Card';
import { useLocation, useNavigate } from 'react-router-dom';
import { BASE_URL, HOSPITAL_DETAILS_URL } from '../config/constants';

function ModalConfirmation(props) {
  const [show, setShow] = useState(props.showModal);
  const [name, setName] = useState();
  const [contact, setContact] = useState();
  const [patientName, setPatientName] = useState();
  const [relation, setRelation] = useState("Self");
  const [address, setAddress] = useState();
  const [comment, setComment] = useState();
  //const [consultation_type, setConsultation_type] = useState();
  const [selfConsult, setSelfConsult] = useState(true);
  const [otherConsult, setOtherConsult] = useState(false);

  const [ftfConsult, setFtfConsult] = useState(true);
  const [videoConsult, setVideoConsult] = useState(false);
  const [validated, setValidated] = useState(false);
  const location = useLocation();
  const navigate = useNavigate()
  const handleBack = () => {//setShow(false)
    let lastURLArray=location.state?.lastURL?.split("?");
    let uri="";
    if(lastURLArray.length>0){
        uri="?"+lastURLArray[1];
    }
    navigate("/"+uri)
  };
  //const handleShow = () => props.callbackhandler(true);
  useEffect(() => {
    // console.debug(location)
    setShow(props.showModal)
  }, [props.showModal])
  const changeForSelf = () => {
    setSelfConsult(true)
    setOtherConsult(false)
    document.getElementById("patient_name").disabled = true;
    document.getElementById("relation").disabled = true;
    document.getElementById("relation").value = "Self";
    setRelation("Self");
  }
  const changeForOthers = () => {
    setOtherConsult(true)
    setSelfConsult(false)
    document.getElementById("patient_name").disabled = false;
    document.getElementById("relation").disabled = false;
    document.getElementById("relation").value = "";
    setRelation("");
  }

  const saveBooking = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    let selectedDate = new Date(location.state?.Date + ' ' + location.state?.ShiftSlots);
    let endDate = new Date(selectedDate.getTime() + 30 * 60 * 1000);
    let endTime = endDate.getTime();
    let consultation_type = document.querySelector('[name="consultation_type"]').value;
    // console.debug('sss');
    // console.debug(`${BASE_URL}`);
    if (form.checkValidity() === false) {
      // return;
      setValidated(true);
    } else {
      let reqBody = {
        "name": name,
        "contact": contact,
        "patient": patientName,
        "relation": relation,
        "address": address,
        "comments": comment,
        "date": location.state?.Date,
        "time": location.state?.ShiftSlots,
        "endTime": endTime,
        "categoryId": location.state?.CategoryId,
        "doctorId": location.state?.DoctorId,
        'tphonenumber': location.state?.tphonenumber,
        'doctorUniqId': location.state?.doctorUniqId,
        'consultation_type': consultation_type,
        'requestFrom':location.state?.requestFrom
      }
//  console.log(reqBody);
// console.debug(`${BASE_URL}/bookappointment`);
      fetch(`${BASE_URL}/bookappointment`, {
        method: 'POST',
        body: JSON.stringify(reqBody),
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then((res) => {
          if (!res.ok) {
            throw new Error('Network response was not ok');
          }
          return res.json(); // use res.text() for a plain text response
        })
        .then((data) => {
          //  console.debug("post data" + data);
          navigate('/thanks', {
            state: {
              "lastURL": location.state?.lastURL,
              "bookingId": data.bookingId
            },
          });
        })
        .catch((error) => {
          console.error('Error during fetch operation:', error);
          // Handle the error as needed
        });
      
    }



  }
  return (

    <Container>
      <Card >
        <Form noValidate validated={validated} onSubmit={saveBooking}>
          <Card.Header >
            <Card.Title>Appointment Booking Details</Card.Title>
          </Card.Header>
          <Card.Body>
            <Container>
              <Row>
                <Col xs={12} sm={12} md={6} lg={6} xl={6} >
                  <Row className="g-2">
                    <div className="bd-callout bd-callout-info">
                      <h6>Are you sure you want to book appointment</h6>
                      <hr />
                      Category: {location.state?.Category} <br />
                      Doctor : {location.state?.Doctor}<br />
                      Date : {location.state?.Date}<br />
                      Time : {location.state?.ShiftSlots}<br />
                      <hr />
                    </div>
                  </Row>
                  <Row className="g-2">
                    <div>
                      <h5>Consultation</h5>
                      <div className="form-check form-check-inline col-5">
                        <input checked={selfConsult} onClick={changeForSelf} name="consultation"  aria-label="Radio button for consultation" type="radio" className="form-check-input" />
                        <label className='form-check-label'>Self</label>
                      </div>
                      <div className="form-check form-check-inline col-5">
                        <input onClick={changeForOthers} checked={otherConsult} name="consultation" aria-label="Radio button for consultation" type="radio" className="form-check-input" />
                        <label className='form-check-label'>Others</label>
                      </div>
                      <div className='clear'><br/></div>
                      <h5>Consultation Type</h5>
                      <div className="form-check form-check-inline col-5">
                        <input onClick={()=>{setFtfConsult(true);setVideoConsult(false)}} name="consultation_type" checked={ftfConsult} aria-label="Radio button consultation Mode" type="radio" className="form-check-input" />
                        <label className='form-check-label'>Face to Face</label>
                      </div>
                      <div className="form-check form-check-inline col-5">
                        <input onClick={()=>{setFtfConsult(false);setVideoConsult(true)}} name="consultation_type" checked={videoConsult} aria-label="Radio button for consultation Mode" type="radio" className="form-check-input" />
                        <label className='form-check-label'>Video</label>
                      </div>
                      <div className='clear'><br/></div>
                    </div>
                  </Row>
                </Col>
                <Col  >
                  <Row className="g-2">
                    <Col md>
                      <FloatingLabel controlId="floatingInputGrid1" label="Name" className="mb-3">
                        <Form.Control required type="text" placeholder="Name" value={name} onChange={(event) => setName(event.target.value)} />
                        <Form.Control.Feedback type="invalid">Please enter the name of the Applicant</Form.Control.Feedback>
                      </FloatingLabel>
                    </Col>
                  </Row>
                  <Row className="g-2">
                    <Col md>
                      <FloatingLabel controlId="floatingInputGrid2" label="Contact" className="mb-3">
                        <Form.Control required type="number" placeholder="Contact" value={contact} onChange={(event) => setContact(event.target.value)} />
                        <Form.Control.Feedback type="invalid">Please enter the Contact of the Applicant</Form.Control.Feedback>
                      </FloatingLabel>
                    </Col>
                  </Row>
                  <Row className="g-2">
                    <Col md>
                      <FloatingLabel label="Patient Name" className="mb-3">
                        <Form.Control disabled required type="text" placeholder="Patient Name" id="patient_name" value={patientName} onChange={(events) => setPatientName(events.target.value)} />
                        <Form.Control.Feedback type="invalid">Please enter the Patient name</Form.Control.Feedback>
                      </FloatingLabel>
                    </Col>
                    <Col md>
                      <FloatingLabel label="Relation" className="mb-3">
                        <Form.Control disabled required type="text" placeholder="Relation" id="relation" value={relation} onChange={(event) => setRelation(event.target.value)} />
                        <Form.Control.Feedback type="invalid">Please enter the relation with Applicant </Form.Control.Feedback>
                      </FloatingLabel>
                    </Col>
                  </Row>
                  <Row className="g-2">
                    <Col md>
                      <FloatingLabel controlId="floatingInputGrid5" label="Address" className="mb-3">
                        <Form.Control required style={{ height: "100px" }} type="textarea" placeholder="Address" onChange={(event) => setAddress(event.target.value)} />
                        <Form.Control.Feedback type="invalid">Please enter the Address</Form.Control.Feedback>
                      </FloatingLabel>
                    </Col>
                  </Row>
                  <Row className="g-2">
                    <Col md>
                      <FloatingLabel controlId="floatingInputGrid6" label="Comment" className="mb-3">
                        <Form.Control style={{ height: "100px" }} type="textarea" placeholder="Comment" onChange={(event) => setComment(event.target.value)} />
                      </FloatingLabel>
                    </Col>
                  </Row>

                </Col>
              </Row>
            </Container>
          </Card.Body>
          <Card.Footer className="d-flex justify-content-end">
            <Button variant="secondary" style={{ marginRight: "0.5%" }} onClick={handleBack}>
              Back
            </Button>{' '}

            <Button type="submit">Book Appointment</Button>
          </Card.Footer>
        </Form>
      </Card>

    </Container>

  );
}

export default ModalConfirmation;