import { useState, useEffect, useContext } from 'react';
import { useSearchParams } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import DropDownRx from '../ui-elements/DropDownRx';
import { BASE_URL, HOSPITAL_DETAILS_URL } from '../config/constants';
//import catAndDoc from '../data/specializationsanddoctors.json'
import { BookingdetailsContext } from '../providers/bookingDetails';
import { useLocation, useNavigate } from 'react-router-dom';


function BookingSelect(props) {
    const { docID, setDocID, specID, setSpecID, doctnumber, setDoctnumber,hosDoctorUniqId,setHosDoctorUniqId } = useContext(BookingdetailsContext);
    const [categories, setCategories] = useState([]);
    const [selectedCat, setSelectedCat] = useState(-1);
    const [doctors, setDoctors] = useState([]);
    const [selectedDoc, setSelectedDoc] = useState([]);
    const [requestFor, setRequestFrom] = useState([]);
    const navigate = useNavigate()
    function handleCallbackFromCategory(categoryId) {
        // console.debug('specialisation selected -- ', categoryId);
        let docList = categories.filter((spec) => spec.key == categoryId).map((currentSpec) => {
            return currentSpec.doctors;
        }).flat().map((currentDoc) => {
            return { 'key': currentDoc.duniqeNumber,
                    'value': currentDoc.name+"-"+(currentDoc.hospitalName?currentDoc.hospitalName:"")+"-"+currentDoc.address,
                    'tnumber': currentDoc.phoneNumber,
                    'doctoruniqid': currentDoc.doctorUniqId }
        })

        setDoctors(docList);
        document.getElementById("Doctor").value = -1;
        setSelectedCat(categoryId)
        setSelectedDoc(-1);
        // console.log('categoryId Set in booking select block as ', categoryId)
        setSpecID(categoryId); // context api
        if (categoryId == -1) {
            document.getElementById("Doctor").classList.add("is-invalid");
            document.getElementById("Category").classList.add("is-invalid");
            //return;
        }
        props.callbackHandler();
    }

    function handleCallbackFromDocDrop(doctorId) {
        // console.debug('Doctor ID trying to set as ', doctorId);

        setDocID(doctorId); // context api
        setSelectedDoc(doctorId);

        setDoctnumber(doctors.filter((doc) => doc.key == doctorId)[0]?.tnumber);

        setHosDoctorUniqId(doctors.filter((doc) => doc.key == doctorId)[0]?.doctoruniqid);

        // console.debug('sloadHospitalSpecialisation received in requestFrom ', requestFrom);
        // console.debug('ssssss  ', doctors.filter((doc) => doc.key == doctorId)[0]?.doctoruniqid);

        if (doctorId == -1) {
            document.getElementById("Doctor").classList.add("is-invalid");
            //return;
        }

        props.callbackHandler();
    }

    const loadHospitalSpecialisation = (hospitalID,hospitalUniqID,requestFrom) => {
        let result = {};
        // console.debug('sloadHospitalSpecialisation received in requestFrom ', requestFrom);
        return result = fetch(`${BASE_URL}${HOSPITAL_DETAILS_URL}/${hospitalID}/${hospitalUniqID}/${requestFrom}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then((res) => res.json())
            .then((data) => {
                // console.debug("Hospital Details received from API", data);
                return data;
            });
    }
    const [searchParams] = useSearchParams();
    // console.debug('search params received in hospitalID', searchParams.get('hospitalID')); 
    const hostpitalidSQ = searchParams.get('hospitalID') || 555;
    const hostpitalUID = searchParams.get('hospitalUniqID') || 555;

    const requestFrom = searchParams.get('requestFrom') || '555';
  
    useEffect(() => {
        if (hostpitalidSQ === 555 || hostpitalUID === 555) {
            navigate('/thanks', {
                state: {},
            });
        } else {
            loadHospitalSpecialisation(hostpitalidSQ, hostpitalUID,requestFrom).then((data) => {
                if (data && data.specializations) {
                    let catList = data.specializations.map((currentSpec) => {
                        return { 'key': currentSpec.key, 'value': currentSpec.value, 'doctors': [...currentSpec.doctors] };
                    });
                    setCategories(catList);
                } else {
                    navigate('/thanks', {
                        state: {},
                    });
                }
            });
        }
    }, []);
    

    return (
        <Card style={{ padding: '1%' }}>
            <Form>
                <Row>
                    <Col className="col-md-6 col-sm-12 col-12">
                        <DropDownRx label="Category" data={categories} selected={selectedCat} callbackHandler={handleCallbackFromCategory} />
                    </Col>
                    <Col className="col-md-6 col-sm-12  col-12">
                        <DropDownRx label="Doctor" data={doctors} selected={selectedDoc} callbackHandler={handleCallbackFromDocDrop} />
                    </Col>
                </Row>
            </Form>
        </Card>
    )
}
export default BookingSelect;