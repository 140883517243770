import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import ReactDOM from 'react-dom/client';
import './css/index.css';
import './css/rx.css';
import App from './App';
import Header from './ui-elements/Header';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom';

const title = 'Powered By 1to1RX';
const link = 'http://www.atruebrand.com';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Router>
      {/* <Header title={<> {title} [<a href={link}>atruebrand.com</a>]</>} /> */}
      <Header title={<> {title}</>} />
   <App />
  </Router>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
