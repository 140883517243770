import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import React, { useState, useEffect } from 'react';
import props from 'prop-types';
import Badge from 'react-bootstrap/Badge';


function TimeSlots(props) {
    const [slotsInCards, setSlotsInCards] = useState([]);

    function handleTimeSlotClick(event) {
    document.getElementById("submitAppointment").removeAttribute("disabled");
        // console.debug(event);
        Array.from(document.getElementsByClassName("shiftslots bg-primary")).forEach((element) => {
            element.classList.add("bg-success");
            element.classList.remove("bg-primary")

        })
        event.target.classList.remove("bg-success");
        event.target.classList.add("bg-primary");
    }

    useEffect(() => {
        const rows = [];
        // console.debug(props);
        props.timeSlotsJson.forEach((timeSlot) => {
            if (timeSlot.available) {
                rows.push(
                    <Col xs={6} sm={6} md={4} lg={3} xl={2} key={timeSlot.slot}>
                        <h3>
                            <Badge className="shiftslots w-75" bg="success" onClick={handleTimeSlotClick}>{timeSlot.slot}</Badge>
                        </h3>
                    </Col>
                );
            } else {
                rows.push(
                    <Col xs={6} sm={6} md={4} lg={3} xl={2} key={timeSlot.slot}>
                        <h3>
                            <Badge className="w-75" bg="secondary">{timeSlot.slot}</Badge>
                        </h3>
                    </Col>
                );
            }

        });
        setSlotsInCards(rows);
        Array.from(document.getElementsByClassName("shiftslots bg-primary")).forEach((element) => {
                    element.classList.add("bg-success");
                    element.classList.remove("bg-primary")

                })
    }, [props.timeSlotsJson])

    /*useEffect(() => {
        const rows = [];
        props.timeSlots.forEach(timeSlot => {

            if (timeSlot.available){
               *//* rows.push(
<Col xs={12} sm={6} md={4} lg={3} xl={2} key={timeSlots.id}>
<Badge bg="success" onClick={handleTimeSlotClick}>timeSlot.slot</Badge>
</Col>
});*//*
}else {
   *//* rows.push(
        <Col xs={12} sm={6} md={4} lg={3} xl={2} key={timeSlots.id}>
        <Badge bg="secondary" onClick={handleTimeSlotClick}>timeSlot.slot</Badge>
        </Col>
    });*//*
}
}
//setSlotsInCards(rows);
},[]);*/

    return (
        <Card >
            <Card.Header>{props.shift}</Card.Header>
            <Card.Body>
                <Row className="justify-content-md-center" >
                    {slotsInCards}
                </Row>
            </Card.Body>
        </Card>
    );
}

export default TimeSlots;