import { Routes, Route, useNavigate, useSearchParams} from 'react-router-dom';
import React, { useState, useEffect, useContext } from 'react';
import Home from './components/Home';
import AppBookConfirmation from './components/AppBookConfirmation';
import Thanks from './components/Thanks';
import { BookingdetailsContext } from './providers/bookingDetails';

export default function App() {
  const navigate = useNavigate();

  const [docID, setDocID] = useState(-1);
  const [specID, setSpecID] = useState(-1);
  const [docName,setDocName]=useState('None');
  const [specName,setSpecName]=useState('None');

  const [doctnumber, setDoctnumber]=useState(0);
  
  const [hosDoctorUniqId, setHosDoctorUniqId]=useState(0);

  const [searchParams] = useSearchParams();
  // console.debug('search params received in app.js', searchParams.get('tnumber'));
  

  const navigateToContacts = () => {
    // 👇️ navigate to /contacts
    navigate('/contacts');
  };
  const navigateHome = () => {
    // 👇️ navigate to /
    navigate('/');
  };

  return (
    <BookingdetailsContext.Provider value={{ docID, setDocID, specID, setSpecID,doctnumber, setDoctnumber,hosDoctorUniqId, setHosDoctorUniqId }}>
      <div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/confirmation" element={<AppBookConfirmation />} />
          <Route path="/thanks" element={<Thanks />} />
        </Routes>
      </div>
    </BookingdetailsContext.Provider>
  );
}