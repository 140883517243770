import { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import InputGroup from 'react-bootstrap/InputGroup';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Card from 'react-bootstrap/Card';
import { useLocation, useNavigate } from 'react-router-dom';
import Image from 'react-bootstrap/Image';
import firstImg from '../images/1.png';
import secondImg from '../images/2.png';
import thirdImg from '../images/3.png';
import fourthImg from '../images/4.png';
import googleplaystore from '../images/google-play-badge.png';

import CardGroup from 'react-bootstrap/CardGroup';


function Thanks(props) {


  const location = useLocation();
  const navigate = useNavigate()
  const handleBack = () => {//setShow(false)
    let lastURLArray=location.state?.lastURL?.split("?");
        let uri="";
        if(lastURLArray.length>0){
            uri="?"+lastURLArray[1];
        }
        navigate("/"+uri)
  };
  //const handleShow = () => props.callbackhandler(true);
  useEffect(() => {
    console.debug(location)

  }, [props.showModal])

  return (

    <Container>
      <Card >
        <Card.Header closeButton>
          <Card.Title>
          <Row>
          <Col className="col-md-6">Thank You!!!</Col>
          <Col className="col-md-6 d-flex justify-content-end">
          <Button variant="secondary" style={{ marginRight: "0.5%" }} onClick={handleBack}>
                                Back
                              </Button>{' '}
          </Col>
          </Row>
          </Card.Title>

        </Card.Header>
        <Card.Body>
          <Container>
            <Row>
              Thank you for booking with us.{location.state.hasOwnProperty("bookingId")? 'Your booking id : ' :' '} {location.state?.bookingId} Track Status with '1to1RX App'
            </Row>
          </Container>
        </Card.Body>
        {/* <Card.Footer className="d-flex justify-content-end">
          <Button variant="secondary" style={{ marginRight: "0.5%" }} onClick={handleBack}>
            Back
          </Button>{' '}
        </Card.Footer> */}
      </Card>

      <Card >
        <Card.Header closeButton>
          <Card.Title>Use the 1to1RX app to track appointments and maintain your family's complete medical records!!!</Card.Title>
        </Card.Header>
        <Card.Body>

          <Container>
            <Row className="justify-content-center align-items-center">
              <Col xs={12} md={4} lg={3} className="text-center">
                <a
                  href='https://play.google.com/store/apps/details?id=com.atruebrand.patient_1to1rx&pli=1&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    alt='Get it on Google Play'
                    src={googleplaystore}
                    className="img-fluid center-image"
                  />
                </a>
              </Col>
            </Row>
          </Container>
          <div className="my-4 div-custom-margin "> {/* Add margin to the div */}
          <Container > 
            <Row>
              <Col xs={12} md={3} lg={3}>
                <Card>
                  <Card.Img variant="top" src={firstImg} rounded />
                  <Card.Body>
                    <Card.Title>Login</Card.Title>
                    <Card.Text>
                    Securely log in using your phone number with a one-time password (OTP) for an added layer of protection. 
                    Our system ensures a secure authentication process, enhancing your account's safety
                    </Card.Text>
                  </Card.Body>
                  <Card.Footer>
                    <small className="text-muted center">...</small>
                  </Card.Footer>
                </Card>
              </Col>
              <Col xs={12} md={3} lg={3}>
                <Card>
                  <Card.Img variant="top" src={secondImg} />
                  <Card.Body>
                    <Card.Title>1to1RX Prescriptions</Card.Title>
                    <Card.Text>
                    Easily retrieve complete family prescription records, whether by doctor or patient, for a seamless healthcare experience.
                    </Card.Text>
                  </Card.Body>
                  <Card.Footer>
                    <small className="text-muted">...</small>
                  </Card.Footer>
                </Card>
              </Col>
              <Col xs={12} md={3} lg={3}>
                <Card>
                  <Card.Img variant="top" src={thirdImg} />
                  <Card.Body>
                    <Card.Title>Comprehensive Navigation Menu</Card.Title>
                    <Card.Text>
                    Explore a range of features to streamline your healthcare experience. 
                    Easily manage family profiles for medical records, seamlessly interactions with doctors, 
                    nursing services, medical stores, and medical labs. Our platform offering all-encompassing healthcare management solution.
                    </Card.Text>
                  </Card.Body>
                  <Card.Footer>
                    <small className="text-muted">...</small>
                  </Card.Footer>
                </Card>
              </Col>
              <Col xs={12} md={3} lg={3}>
                <Card>
                  <Card.Img variant="top" src={fourthImg} />
                  <Card.Body>
                    <Card.Title>Appointment Tracking Screen</Card.Title>
                    <Card.Text>
                    Stay informed about the progress of your appointments with our intuitive Appointment Tracking Screen. 
                    Easily monitor real-time updates on appointment status, including confirmed, pending, 
                    or completed appointments. Streamline your scheduling experience.
                    </Card.Text>
                  </Card.Body>
                  <Card.Footer>
                    <small className="text-muted">...</small>
                  </Card.Footer>
                </Card>
              </Col>
            </Row>
          </Container>
          </div>

          
        </Card.Body>
        <Card.Footer className="d-flex justify-content-end">
          <Button variant="secondary" style={{ marginRight: "0.5%" }} onClick={handleBack}>
            Back
          </Button>{' '}
        </Card.Footer>
      </Card>
    </Container>



  );
}

export default Thanks;