import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import React, { useState, useEffect } from 'react';
import props from 'prop-types';
import Button from 'react-bootstrap/Button';


function DateSlots(props) {
    const [daySlots, setDaySlots] = useState([]);
    Date.prototype.toShortFormat = function () {
        const monthNames = ["Jan", "Feb", "Mar", "Apr",
            "May", "Jun", "Jul", "Aug",
            "Sep", "Oct", "Nov", "Dec"];
        const day = String(this.getDate()).padStart(2, '0');
        const monthIndex = this.getMonth();
        const monthName = monthNames[monthIndex];
        const year = this.getFullYear();
        return `${day}-${monthName}-${year}`;
    }
    function handleSlotClick(event) {
        Array.from(document.getElementsByClassName("slot btn-primary")).forEach((element) => {
            element.classList.add("btn-outline-primary");
            element.classList.remove("btn-primary");
        })
        event.target.classList.remove("btn-outline-primary");
        event.target.classList.add("btn-primary");
        let doctorElement = document.getElementById("Doctor");
        let categoryElement = document.getElementById("Category");
        // console.debug(`Doc ID ${doctorElement.value} Cat ID ${categoryElement.value}`);
        if (doctorElement.value !== -1) {
            props.callbackHandler();
        } else {
            //console.debug("aLL SELECTED");
            doctorElement.classList.add("is-invalid")
            if (categoryElement.value == -1) {
                categoryElement.classList.add("is-invalid");
            }
        }
        //console.debug(event.target);
    }
    useEffect(() => {
        const rows = [];
        var today = new Date();
        for (let i = 0; i < 7; i++) {
            var todayDate = today.getDate() + "-" + today.getMonth() + "-" + today.getYear();
            var dayName = today.toLocaleDateString("en-US", { weekday: 'short' });
            if (today.getDate() == new Date().getDate()) {
                rows.push(<li key={dayName} className="list-group-item date-list">{dayName}<br /><Button variant="primary" className="slot" onClick={handleSlotClick}>{today.toShortFormat()}</Button ></li>);

            } else {
                rows.push(<li key={dayName} className="list-group-item date-list">{dayName}<br /><Button variant="outline-primary" className="slot" onClick={handleSlotClick}>{today.toShortFormat()}</Button ></li>);
            }
            today.setDate(today.getDate() + 1);

        }
        setDaySlots(rows)
    }, []);

    return (
        <Card style={{ padding: '1%', whiteSpace: "nowrap", textAlign: "center" }}>
            <ul className="list-group list-group-horizontal ">
                {daySlots}
            </ul>
        </Card>
    );
}

export default DateSlots;